import React from "react";
import { Link } from "react-router-dom";

import "./button.scss";

const ButtonOne = () => {
  return (
    <a href="/" className="btn-action">
      Join Now
    </a>
  );
};

export default ButtonOne;
