import React from "react";
import Header from "../components/header/Header";
import About from "../components/layouts/About";
import SliderTwo from "../components/slider/SliderTwo";
import Counter from "../components/layouts/Counter";
import FooterStyle2 from "../components/footer/FooterStyle2";
import MarqueeImages from "../components/marquee/Marquee";
import dataRoadMap from "../assets/fake-data/data-roadmap";
import BugfixTeam from "../components/layouts/TeamSection";
import ContactForm from "../components/contact-form";

const Home02 = () => {
  return (
    <div className="home-2">
      <Header />
      <SliderTwo />
      <About />
      <BugfixTeam data={dataRoadMap} />
      <MarqueeImages />
      <ContactForm />
      {/* <Counter /> */}
      <FooterStyle2 />
    </div>
  );
};

export default Home02;
