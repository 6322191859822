const marqueItems = {
  item1: require("../../assets/images/marquee-images/1.png"),
  item2: require("../../assets/images/marquee-images/2.png"),
  item3: require("../../assets/images/marquee-images/3.png"),
  item4: require("../../assets/images/marquee-images/4.png"),
  item5: require("../../assets/images/marquee-images/5.png"),
  item6: require("../../assets/images/marquee-images/6.png"),
  item7: require("../../assets/images/marquee-images/7.png"),
  item8: require("../../assets/images/marquee-images/8.png"),
  item9: require("../../assets/images/marquee-images/9.png"),
  item10: require("../../assets/images/marquee-images/10.png"),
  item11: require("../../assets/images/marquee-images/11.png"),
  item12: require("../../assets/images/marquee-images/12.png"),
  item13: require("../../assets/images/marquee-images/13.png"),
  item14: require("../../assets/images/marquee-images/14.png"),
  item15: require("../../assets/images/marquee-images/15.png"),
  item16: require("../../assets/images/marquee-images/16.png"),
  item17: require("../../assets/images/marquee-images/17.png"),
  item18: require("../../assets/images/marquee-images/18.png"),
  item19: require("../../assets/images/marquee-images/19.png"),
  item20: require("../../assets/images/marquee-images/20.png"),
  item21: require("../../assets/images/marquee-images/21.png"),
  item22: require("../../assets/images/marquee-images/22.png"),
  item23: require("../../assets/images/marquee-images/23.png"),
  item24: require("../../assets/images/marquee-images/24.png"),
  item25: require("../../assets/images/marquee-images/25.png"),
  item26: require("../../assets/images/marquee-images/26.png"),
  item27: require("../../assets/images/marquee-images/27.png"),
};

export default marqueItems;
