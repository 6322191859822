import React from "react";
import { Link } from "react-router-dom";

const BugfixTeam = (props) => {
  const data = props.data;

  return (
    <>
      <section className="tf-section road-map mb-24" id="team-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text center">
                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000">
                  <span>Team</span>
                </h1>
                <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">
                  Team
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container !mb-0">
          <div className="row">
            <div className="col-12">
              <div className="road-map__content s1">
                {data.map((data, index) => (
                  <div className="h-56" key={index}>
                    <div className={`box-time ${data.class} py-30`} data-aos="fade-in">
                      <span data-aos="zoom-in" data-aos-offset="300">
                        <i className="fa fa-check"></i>
                      </span>
                      <p className="fs-16 color-main mb-0">{data.time}</p>
                      <h5 className="title mb-20">{data.title}</h5>
                      <p className="fs-18">{data.desc} </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex justify-center mb-24">
        <a type="submit" className="btn-action style-2" href="#contact-section">
          <span>Join Our Growing Team</span>
        </a>
      </div>
    </>
  );
};

export default BugfixTeam;
