import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const FooterStyle2 = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      <footer className="footer style-2 mt-200">
        <div className="container">
          <div className="row">
            <div className="footer__body">
              <div className="col-xl-3 col-md-6 col-12">
                <div className="info">
                  <Link to="/">
                    <img src={logo} alt="Monteno" style={{ width: "100px" }} />
                  </Link>
                  <p className="desc fs-18">
                    Creating worlds, crafting experiences.
                  </p>
                  <ul className="social">
                    <li>
                      <Link to="https://www.linkedin.com/company/bugfix-games/">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/bugfixgames/">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-6"></div>
              <div className="col-xl-3 col-md-6 col-6">
                <div className="link s2">
                  <h5 className="title">Navigate</h5>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/#about-section">About Us</a>
                    </li>
                    <li>
                      <a href="/#team-section">Team</a>
                    </li>
                    <li>
                      <a href="/#contact-section">Jobs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12">
                <div className="newsletter">
                  <h5 className="title">Newsletter</h5>
                  <p className="fs-18">
                    Follow up to date with our latest news and games.
                  </p>
                  <form action="#">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Your email here"
                        required
                      />
                      <i className="fa fa-envelope"></i>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="footer_bottom">
              <p className="fs-16">
                Copyright © 2023, BUGFIX GAMES ENTERTAINMENT TEKNOLOJİ ANONİM
                ŞİRKETİ
              </p>
              <ul>
                <li>
                  <Link to="/terms">Terms & Condition</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </>
  );
};

export default FooterStyle2;
