import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [isSending, setIsSending] = useState(false);
  const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

  const submitFormHandler = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const formData = Object.fromEntries(data.entries());
    setIsSending(true);
    emailjs.send(serviceId, templateId, formData, publicKey).then(
      function (response) {
        setIsSending(false);
        window.alert("Your message has been sent successfully!");
      },
      function (error) {
        window.alert("Failed to send message. Please try again later.");
        setIsSending(false);
      }
    );
  };

  return (
    <section className="tf-section contact" id="contact-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h1
                className="heading-bg"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <span>Reach Us</span>
              </h1>
              <h5
                className="sub-title mb-10"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Send Message
              </h5>
              <h3
                className="title mb-28"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Get In Touch
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="contact-left">
              <ul>
                <li data-aos="fade-up">
                  <div className="icon">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <div className="info">
                    <h5>Address</h5>
                    <p className="fs-18">
                      Üniversiteler Mah. İhsan Doğramacı Bul. NO: 27 / B01
                      <br />
                      ÇANKAYA /ANKARA
                    </p>
                  </div>
                </li>
                {/* <li data-aos="fade-up">
                <div className="icon">
                  <i className="fas fa-phone-alt"></i>
                </div>
                <div className="info">
                  <h5>Phone</h5>
                  <p className="fs-18">+123 456 7890</p>
                </div>
              </li> */}
                {/* <li data-aos="fade-up">
                  <div className="icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="info">
                    <h5>Email</h5>
                    <p className="fs-18">info@bugfix.games</p>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <form className="contact__form" onSubmit={submitFormHandler}>
              <div className="form-group">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Your name"
                  required
                />
                <input
                  type="email"
                  className="form-control mr-0"
                  id="email"
                  name="email"
                  placeholder="Your email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder="Your phone"
                  required
                />
                <select
                  className="form-control mr-0"
                  id="emailFor"
                  name="emailFor"
                >
                  <option>Apply for a job</option>
                  <option>About Partnerships</option>
                  <option>Other</option>
                </select>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="3"
                  placeholder="Your message here"
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn-action style-2 cursor-pointer disabled:cursor-default"
                disabled={isSending}
              >
                <span>Send Now</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
