const item = {
    item1 : require('../images/items/item-01.png'),
    item2 : require('../images/items/item-02.png'),
    item3 : require('../images/items/item-03.png'),
    item4 : require('../images/items/item-04.png'),
    item5 : require('../images/items/item-05.png'),
    item6 : require('../images/items/item-06.png'),
    item7 : require('../images/items/item-07.png'),
    item8 : require('../images/items/item-08.png'),
    item9 : require('../images/items/item-09.png'),
    item10 : require('../images/items/item-10.png'),
    item11 : require('../images/items/item-11.png'),
    item12 : require('../images/items/item-12.png'),
    item13 : require('../images/items/item-13.png'),
    item14 : require('../images/items/item-14.png'),
    item15 : require('../images/items/item-15.png'),
    item16 : require('../images/items/item-16.png'),
    item27 : require('../images/items/item-27.png'),
    item28 : require('../images/items/item-28.png'),
    item29 : require('../images/items/item-29.png'),
    heroleft: require('../images/hero/image_Joystick.png'),
    heroright: require('../images/hero/image_Mobile.png'),
    technology : require('../images/items/technology.png'),
}

export default item;