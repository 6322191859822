import React, { useState } from "react";
import { Link } from "react-router-dom";
import item from "../../assets/fake-data/item";

const SliderTwo = () => {
  const [datatext] = useState({
    subtitle: "Sculpting Play, Pixel by Pixel",
    title: "Collect Next Generation NFTs Today",
    desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  });

  return (
    <section className="tf-section hero-slider style-2 m-auto">
      <div className="container-fluid m-auto">
        <div className="row">
          <div className="flex">
            <div className="flex items-center text-center m-auto">
              <div className=" ">
                <img
                  src={item.heroleft}
                  alt="Monteno"
                  className="w-full max-w-md"
                />
              </div>
              <div className="block-text">
                <h6 className=" mb-6" data-aos="fade-up">
                  {datatext.subtitle}
                </h6>
                <br />
                <h3 className="title mb-26 text-4xl" data-aos="fade-up">
                  Creating Worlds, Crafting <br />
                  Experiences
                </h3>
                {/* <p className="desc mb-53" data-aos="fade-up">
                  {datatext.desc}
                </p> */}
                <a
                  href="#contact-section"
                  className="btn-action style-2"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  Get Connected
                </a>
              </div>
              <div className=" ">
                <img
                  src={item.heroright}
                  alt="Monteno"
                  className="w-full max-w-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderTwo;
