const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "About Us",
    links: "/#about-section",
  },
  {
    id: 3,
    name: "Team",
    links: "#team-section",
  },
  {
    id: 4,
    name: "Jobs",
    links: "/#contact-section",
  },
  {
    id: 5,
    name: "Contact Us",
    links: "/#contact-section",
  },
];

export default menus;
