const dataRoadMap = [
  {
    title: "Thoughtful Design",
    desc: "Every member brings specialized skills, ensuring our games are built with expert care and attention.",
    class: "left",
  },
  {
    title: "Streamlined Creativity",
    desc: "Our team’s organizational prowess means ideas turn into engaging games efficiently and effectively.",
    class: "right",
  },
  {
    title: "Passion for Gaming",
    desc: "We share an unwavering enthusiasm for creating games that players love.",
    class: "left mt-200",
  },
  {
    title: "Dynamic Collaboration",
    desc: "We harness the synergy of our team to tackle ambitious projects and innovate in the mobile gaming space.",
    class: "right mt-187",
  },
];

export default dataRoadMap;
