import { React, useState } from "react";
import { Link } from "react-router-dom";
import aboutImage from "../../assets/images/aboutus/Image_Abouts.png";
import item from "../../assets/fake-data/item";

const About = () => {
  const [data] = useState({
    subtitle: "About Us",
    title: "Bugfix Games",
    desc1:
      "Sed ut perspiciatis unde omnis iste natus enim ad minim veniam, quis nostrud exercit",
    desc2:
      "At BugFix Games, our games are shaped by a passion for player delight and exceptional artistry. We blend timeless design principles with a modern touch to craft immersive gaming experiences. As a compact team of talented creators, we focus on what we do best: building high-quality games that capture hearts and challenge minds. Join us on a journey through captivating worlds where every play is a story.",
  });

  return (
    <section className="tf-section section-about" id="about-section">
      <div className="container">
        <div className="row reverse">
          <div className="col-xl-7 col-md-12">
            <div className="group-image">
              <img src={aboutImage}></img>
            </div>
          </div>
          <div className="col-xl-5 col-md-12">
            <div className="block-text pt-12">
              <h5
                className="sub-title mb-10"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {data.subtitle}
              </h5>
              <h3
                className="title mb-58"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {data.title}
              </h3>
              {/* <p
                className="fs-21 mb-33"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {data.desc1}
              </p> */}
              <p
                className="fs-18 line-h17 mb-41"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {data.desc2}
              </p>
              <a
                href="#team-section"
                className="btn-action style-2"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                More About Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
