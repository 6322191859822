import React from "react";
import marqueItems from "./marqueItems";
import Marquee from "react-fast-marquee";
const MarqueeImages = () => {
  const firstLineKeys = Object.keys(marqueItems).slice(0, 9);
  const secondLineKeys = Object.keys(marqueItems).slice(9, 18);
  const thirdLineKeys = Object.keys(marqueItems).slice(18, 27);

  return (
    <section className="mb-24 mt-64">
      <Marquee autoFill className="mb-8">
        {firstLineKeys.map((key) => (
          <img
            key={key}
            src={marqueItems[key]}
            alt={key}
            className="w-32 mr-8"
          />
        ))}
      </Marquee>

      <Marquee autoFill className="mb-8" direction="right">
        {secondLineKeys.map((key) => (
          <img
            key={key}
            src={marqueItems[key]}
            alt={key}
            className="w-32 mr-8"
          />
        ))}
      </Marquee>
      <Marquee autoFill className="mb-8">
        {thirdLineKeys.map((key) => (
          <img
            key={key}
            src={marqueItems[key]}
            alt={key}
            className="w-32 mr-8"
          />
        ))}
      </Marquee>
    </section>
  );
};

export default MarqueeImages;
